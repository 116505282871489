/* Font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: "Consolas";
  src: url("./fonts/Consolas.ttf") format("ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  scroll-behavior: smooth;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(60deg, #1e64c0 50%, #1450a0 50%);
}
body::-webkit-scrollbar {
  background: #7FC8A9;
}
body::-webkit-scrollbar-thumb {
  background: #3D84B8;
}
input,
select {
  outline: none;
  border: none;
}