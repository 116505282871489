/* Title */
.title {
  background: #3392ff;
  color: #ffffff;
  text-shadow: 4px 4px 0 #1481fd;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Main */
main {
  width: 100%;
  height: 88vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  grid-template-rows: 450px;
  justify-content: center; 
  align-items: center;
  gap: 20px;
  padding: 50px;
}
main > div {
  border-radius: 7px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* Settings */
.settings {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.settings .all-settings {
  height: 415px;
  width: 100%;
  overflow-y: scroll;
  background: #2d4059;
  color: #ffffff;
  padding: 10px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
::-webkit-scrollbar {
  width: 8px;
  background: #fbfbfb;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 30px;
}
.settings .all-settings .set {
  background: #222831;
  margin: 5px 0;
  padding: 9px;
  border-radius: 5px;
  height: auto;
}
.settings .all-settings .set h3 {
  margin-bottom: 8px;
}
.settings .all-settings .set label {
  display: flex;
  font-size: 17px;
  font-weight: 500;
}
.settings .all-settings .set label.label:not(:last-child) {
  margin-bottom: 10px;
}
.settings .all-settings .set input,
.settings .all-settings .set label input,
.settings .all-settings .set select {
  font-size: 15px;
  padding: 3px 5px;
  width: auto;
  border-radius: 5px;
}
.settings .all-settings .set label input,
.settings .all-settings .set label select {
  margin-left: 5px;
}
.settings .all-settings .set input[type="color"] {
  width: 70px;
  border: none;
  border-radius: 7px;
  padding: 0;
}
.settings .all-settings .set input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 7px;
}
.settings .all-settings .set input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0px;
}
.button-div {
  height: 415px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #979797;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.button-box .button-div button {
  user-select: none;
}
/* Source */
.source {
  position: relative;
}
.source .code {
  padding: 20px;
  width: 100%;
  height: 415px;
  background: #1e1e1e;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  position: relative;
  overflow-x: scroll;
}
.source .code pre {
  color: #d7ba7d;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
}
.source .code span,
.source .code small {
  font-family: "Consolas", sans-serif !important;
}
.source .code pre span {
  color: #74ceff;
}
.source .code pre small {
  color: #c68b73;
  font-size: 17px;
}
.source .code pre .className {
  color: #d7ba7d;
}
.source .copy {
  width: 100%;
  border: none;
  outline: none;
  padding: 15px 0;
  background: #368b85;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  color: #ffffff;
}
/* Top Bar */
.top-bar {
  height: 35px;
  background: #dcdcdb;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #d1d1d1;
}
.top-bar h3 {
  font-size: 16px;
  font-weight: 700;
  color: #222222;
  user-select: none;
}
.top-bar .box {
  position: absolute;
  width: 80px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.top-bar .box span {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #ff6058;
  border: 1px solid #ff4238;
  cursor: pointer;
}
.top-bar .box .minimize {
  background: #ffbd2d;
  border-color: #fab011;
}
.top-bar .box .maximize {
  background: #28c940;
  border-color: #22eb40;
}
footer {
  width: 100%;
  height: 70px;
  background: #1E3163;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
}
footer p {
  font-size: 16px;
  font-weight: 500;
}
footer p a {
  color: #80c6ff;
  text-decoration: none;
}
footer p a:hover {
  text-decoration: underline;
}

@media (max-width: 992px) {
    .title {
      height: 8vh;
    }
    main {
      display: flex;
      row-gap: 20px;
      padding: 20px 5px;
      margin: 0 auto;
      margin-top: 30px; 
      height: auto;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    main > div {
      width: 48%;
      height: 450px;
    }
    footer p {
      font-size: 16px;
    }
}

@media (max-width: 576px) {
  main {
    grid-template-columns: 1fr !important;
    row-gap: 20px;
    padding: 20px 5px;
    margin: 0 auto;
    margin-top: 30px;
    height: auto;
  }
  main > div {
    width: 100% !important;
  }
  footer p {
    font-size: 14px;
  }
}